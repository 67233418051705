import 'owl.carousel';
import 'magnific-popup';

jQuery( document ).ready(function($) {
    $('.newsletter').on('submit', function(e) {
        e.preventDefault();
        const DATA = $(this).serialize();

        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            dataType: 'json',
            data: DATA,
            xhrFields: {
                withCredentials: false,
            },
            success: function (data) {
                $('.inputs-wrap').html('<p class="newsletter-thanks">Thanks for signing up!</p>');
            },
            error: function (err) {
                console.log(err);
            }
        });
    });

    $('.music-carousel').owlCarousel({
        loop:true,
        margin:0,
        nav: true,
        dots: false,
        items:1, 
        navText:[
            '<span class = "sr">Previous</span><i class = "fas fa-chevron-left"></i>',
            '<span class = "sr">Next</span><i class = "fas fa-chevron-right"></i>'
        ],
        responsive: { 
            800: {
                items: 3,
                margin:30
            }
        }
    });
    $('.vid-carousel').owlCarousel({
        loop:true,
        margin:0,
        nav: true,
        dots: false,
        items:1, 
        navText:[
            '<span class = "sr">Previous</span><i class = "fas fa-chevron-left"></i>',
            '<span class = "sr">Next</span><i class = "fas fa-chevron-right"></i>'
        ],
        responsive: { 
            0: {
                items: 1,
            },
            800: {
                items: 2,
            }
        }
    });

    // $.magnificPopup.open({
    //     items: {
    //       src: '#popup',
    //       type: 'inline'
    //     }
    // })
});